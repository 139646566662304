exports.components = {
  "component---node-modules-jaenjs-jaen-src-internal-plugins-pages-internal-services-routing-pages-tsx": () => import("./../../../node_modules/@jaenjs/jaen/src/internal-plugins/pages/internal/services/routing/pages/_.tsx" /* webpackChunkName: "component---node-modules-jaenjs-jaen-src-internal-plugins-pages-internal-services-routing-pages-tsx" */),
  "component---node-modules-jaenjs-jaen-src-ui-loaded-admin-page-tsx": () => import("./../../../node_modules/@jaenjs/jaen/src/ui/LoadedAdminPage.tsx" /* webpackChunkName: "component---node-modules-jaenjs-jaen-src-ui-loaded-admin-page-tsx" */),
  "component---node-modules-jaenjs-jaen-src-ui-login-page-tsx": () => import("./../../../node_modules/@jaenjs/jaen/src/ui/LoginPage.tsx" /* webpackChunkName: "component---node-modules-jaenjs-jaen-src-ui-login-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-shopping-cart-tsx": () => import("./../../../src/pages/shopping-cart.tsx" /* webpackChunkName: "component---src-pages-shopping-cart-tsx" */),
  "component---src-templates-collection-page-template-tsx": () => import("./../../../src/templates/CollectionPageTemplate.tsx" /* webpackChunkName: "component---src-templates-collection-page-template-tsx" */),
  "component---src-templates-product-page-template-tsx": () => import("./../../../src/templates/ProductPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-page-template-tsx" */),
  "component---src-templates-products-page-template-tsx": () => import("./../../../src/templates/ProductsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-products-page-template-tsx" */)
}

